<script>
// import Datepicker from "vue3-datepicker";
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";

/**
 * Task-create component
 */
export default {
  page: {
    title: "Create Task",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    // Datepicker,
    // ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    QuillEditor,
  },
  data() {
    return {
      titre: "",
      process: false,
      progress: "",
      huissierName: "",
      regulationContent: "",
      title: "Modifier un article",
      awardDetails: [],
      items: [
        {
          text: "Awards",
          href: "/award/awardList",
        },
        {
          text: this.$route.params.awardName,
          href: "/award/awardDetail/" + this.$route.params.id,
        },
        {
          text: "Règlement",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      editor: ClassicEditor,
      teamMember: [{ id: 1 }],
    };
  },
  methods: {
    addRgulationInAward() {
      this.titre = "Création de l'article";
      // this.progress = true;
      this.progressValue = 0;
      this.process = true;
      this.modalOk = false;

      const onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        console.log(`Upload progress: ${this.progress}% et ${progress}`);
      };

      Api.put(
        `/voting/rest/awards/update-huissier-awards/${this.$route.params.id}`,{
          huissier: this.huissierName,
        },
        {onUploadProgress}
      )
        .then(function (response) {
          Swal.fire("Success!", "Règlement modifié", "success");
          console.log(response);
          this.process = false;
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });

        
      Api.put(
        `/voting/rest/awards/update-reglement-awards/${this.$route.params.id}`,{
            reglementation: this.regulationContent,
        },
        {onUploadProgress}
      )
        .then(function (response) {
          Swal.fire("Success!", "Règlement modifié", "success");
          console.log(response);
          this.process = false;
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },

  mounted() {
    this.process = true
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };
    Api.get(`/voting/rest/awards/${this.$route.params.id}`, {
      onUploadProgress,
    })
      .then((res) => {
        this.awardDetails = res.data.content;
        console.log(this.awardDetails);
        this.regulationContent = this.awardDetails.reglementation;
        this.huissierName = this.awardDetails.huissier;
        this.process = false
      })
      .catch((error) => {
        console.log(error);
        Erreur.gestionErreur(error.message);
        this.process = false
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-10">
        <button
          type="submit"
          @click="addRgulationInAward"
          class="btn c2play-primary"
        >
          <b-spinner v-if="process == true" variant="success" role="status"></b-spinner>
          <span v-else>Enregistrer</span>
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Editer le règlement</h4>
            <form class="outer-repeater">
              <div data-repeater-list="outer-group" class="outer">
                <div data-repeater-item class="outer">
                  <div class="row">
                    <div class="form-group col-lg-12 mb-2">
                      <label for="taskname" class="col-form-label"
                        >Nom de l'huissier</label
                      >
                      <div class="col-lg-12">
                        <input
                          id="taskname"
                          name="taskname"
                          type="text"
                          class="form-control"
                          placeholder="Saisissez le nom de l'huissier"
                          v-model="huissierName"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-4">
                    <label class="col-form-label">Règlement</label>
                    <div class="col-lg-12">
                      <QuillEditor
                        v-model:content="regulationContent"
                        toolbar="full"
                        content="html"
                        contentType="html"
                        theme="snow"
                      />
                      <!-- <ckeditor :editor="editor" v-model="content"></ckeditor> -->
                    </div>
                  </div>
                  <br /><br />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
